@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';

/* Mode portrait ci-dessous */
.App {
  text-align: center;
}

html,
body {
  position: relative;
  margin: 0px;
  height: 100%;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: white;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.row {
  display: block !important;
  padding: 0 28px !important;
  margin: 0 !important;
  position: relative;
  top: 25px;
  width: 100%;
}

.row>* {
  width: 50% !important;
}


.split {
  display: flex;
  height: 100%;
  position: relative;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  overflow: visible;
  text-align: center;

}

.left {
  left: 0;
  float: left;

}

.right {
  right: 0;
  float: right;

}

.video-iframe{
  height: 500px;
}

.image {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 2.5rem;
  width: 100%;
}

.productImage {
  height: 400px;
}

.image1 {
  /*height: 400px;*/
  height: 300px;
}

.image2 {
  height: 300px;
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
}

#img{
  height: 500px!important;
  width: 100%!important;
}

.container-image img {
  vertical-align: middle;
}

.block {
  display: block !important;
}

.mybutton {
  position: fixed;
  bottom: 32px;
  right: 5px;
  z-index: 10;

}

.mybutton-QR {
  position: fixed;
  bottom: 120px;
  right: 5px;
  z-index: 10;

}

.centerModalTitle {
  text-align: center;
  width: 100%;
}

.feedback {
  margin: 5px;
  width: 80px;
  height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 6px;
  background-color: #F61728;
  color: #ffffff !important;
  transition: transform 500ms;
  border-radius: 50%;
  font-size: 12px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 44px;
  line-height: 45px;
  color: white;
  overflow-wrap: break-word;
  width: 85%;
}

.filtre {
  opacity: 0.6;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

a {
  text-decoration: none !important;
}

a img {
  border: 0 !important;
  outline: none !important;
}

.events {
  display: flex;
  width: 100%;
  height: 500px;
  text-align: center;
  /* margin-top: 7%; */
  margin-top: 0%;
  align-items: center;
  justify-content: center;
}

.events:empty {
  display: none;
}

.center-crop {
  width: 92%;
  height: 500px;
  object-fit: cover;
  object-position: center;
  border-radius: 2.25rem;
}

.logo {
  position: absolute;
  z-index: 2;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageLogo {
  width: 26%;
  position: absolute;
  z-index: 2;
  top: 0;
}

/* .fullDate {
  position: absolute;
  display: inline-block;
  width: 35%;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: black;
  margin: 1%;
  left: 4%;
  padding: 0.3%;
} */

.date {
  display: block;
  line-height: 6em;
  justify-content: end;
  align-items: center;
  height: 100%;
}

.fullDate {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  color: rgb(0, 0, 0);
  padding: 0.3%;
  margin-left: 23px;
}

.trait {
  border-top: 4px solid rgb(0, 0, 0);
  width: 100%;
}

.meteo {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: 0;
  width: 37%;
  margin: 1%;
  padding: 0.3%;
}

.temp {
  display: flex;
  margin-right: 5%;
  font-size: 32px;
  font-weight: 500;
}

.wind {
  display: flex;
  margin-top: 5px;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

.goBack {
  height: 80px;
  width: 80px;
  bottom: 207px;
  right: 5px;
  border-radius: 50%;
  background-color: rgb(52, 52, 52);
  padding: 6px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  margin: 5px;
  padding-top: 10px;
}

.goLeft {
  position: fixed;
  display: flex;
  height: 65px;
  width: 65px;
  border-radius: 1em;
  background-color: black;
  justify-content: center;
  align-items: center;
  margin: 5%;
  top: 50%;
  z-index: 4;
  left: 0;
}

.goRight{
  position: fixed;
  display: flex;
  height: 65px;
  width: 65px;
  border-radius: 1em;
  background-color: black;
  justify-content: center;
  align-items: center;
  margin: 5%;
  top: 50%;
  z-index: 4;
  right: 0;
}

.goHome {
  position: fixed;
  display: flex;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: rgb(52, 52, 52);
  justify-content: center;
  align-items: center;
  padding: 6px;
  margin: 5px;
  z-index: 4;
  padding-top: 10px;
  right: 0;
  bottom: 295px;
  right: 5px;
}

.myVideo {
  z-index: 5;
  position: fixed;
  object-fit: cover;
  object-position: center;
  background-color: black;
  display: none;
  width: 100%;
  height: 100%;
}

.myHeader {
  display: flex;
  flex-direction: row;
}

.firstRow {
  display: flex;
  flex-direction: row;
}

.weather-icon {
  margin: auto;
}

.weather-icon-loading {
  margin: auto;
  line-height: 1;
  margin-left: 40px;
}

.windspeed {
  color: black;
  font-size: 30px;
  /*font-weight: bold;*/
  display: flex;
  justify-content: center;
}

.iframe-box {
  margin: 0px;
  height: 100vh;
}

.content-box {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.media-content {
  width: 100%;
  object-fit: contain;
}

.qr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.screensaver-banner {
  position: fixed;
  color: white;
  width: 100%;
  height: 10%;
  z-index: 5;
  padding-top: 5%;
  font-size: 64px;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
}

.carousel-container {
  will-change: transform;
  overflow: hidden;
  position: relative;
  width: 100%;/* Ajustez en fonction de la hauteur souhaitée */
}

.carousel-container video {
  will-change: transform;
  width: 100%; /* Ajustez selon vos besoins */
}

.connexion-banner {
  position: fixed;
  color: whitesmoke;
  width: 100%;
  height: 10%;
  z-index: 1000000;
  bottom: 0;
  text-align: center;
  padding-top: 7%;
  font-size: 50px;
  background: linear-gradient(to top, rgba(255, 0, 0, 1), rgba(255, 0, 0, 0));
}

.connexion-error-content {
  display: flex;
  flex-direction: column;
  font-size: xx-large;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 5%;
  border-radius: 1em;
}

.connexion-error-content>Button {
  margin-top: 10%;
  padding: 2%;
  border-radius: 1em;
}

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 3px 3px 20px #ccc;
  padding: 5%;
  border-radius: 1em;
}

.background-view {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("/public/assets/background_login.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-weather{
  margin-left: 20px;
  font-size: 30px!important;
}



/* Mode paysage ci-dessous */
@media (min-width: 1920px){
  .events {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 410px;
    text-align: center;
    /* margin-top: 5%; */
    margin-top: 0%;
  }

  .btnPMR {
    display: "none";
  }

  .media-content {
    height: 100vh;
  }

  .center-crop {
    width: 95%;
    height: 410px;
    object-fit: cover;
    object-position: center;
    border-radius: 2.25rem;
  }

  .image1 {
    /*height: 300px;*/
    height: 250px;
  }

  .image2 {
    height: 250px;
  }

  .goBack {
    margin-top: 10%;
  }

  .goHome {
    margin-top: 10%;
  }

  .row {
    top: 10px;
  }

  .myVideo {
    z-index: 5;
    position: fixed;
    object-fit: cover;
    object-position: center;
    background-color: black;
    display: none;
    width: 100%;
    height: 100%;
  }

  .screensaver-banner {
    padding-top: 0;
    height: 150px;
  }

  .screensaver-container{
    padding-top: 50px;
  }

  video .carousel-item {
    width: 100%;
    height: 100%;
  }

  img .carousel-item {
    width: 100%;
  }

  .logo {
    top: 5%;
  }

  .imageLogo {
    /*height: 100%;*/
    width: 12%;
  }

  .connexion-banner {
    padding-top: 2.2%;
    height: 13%;
  }

  .content-box {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .firstRow {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 90px;
    z-index: 1;
    background-color: white;
  }
  .fullDate {
    font-size: 34px;
    font-weight: 400;
    text-align: center;
    color: rgb(0, 0, 0);
    padding: 0.3%;
  }

  .center-weather{
    margin-left: 70px;
    font-size: 34px!important;
  }

  .single-column-row {
    display: block !important;
    padding: 0 35px !important;
    margin: 0 !important;
    position: relative;
    top: 25px;
    width: 10%;
  }

  .single-column-row>* {
    width: 50%;
  }

  .single-column {
    flex-direction: column;
  }

  .screensaver-container {
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center; /* Ajustement pour centrer horizontalement */
    align-items: center;
    width: 100%;
  }

  .ss_img1,
  .ss_img2 {
    height: auto;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .ss_img1 {
    width: 13%;
    margin-bottom: 20px;
    animation: fadeInOut 2s infinite;
  }

  .ss_img2 {
    width: 55%;
    margin-bottom: 0px;
    animation: fadeInOut 2s infinite;
  }

  .fade-container {
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    transition: background-color 5s ease-in-out; /* Transition over 5 seconds */
  }

  .fade-container.fade-in {
    background-color: rgba(0, 0, 0, 1); /* Fully opaque background */
  }


}




/* Mode Tablette et téléphone */
@media only screen and (max-width: 768px){
  .row>* {
    width: 100% !important;
  }

  .image1{
    height: 260px;
  }

  .image2{
    height: 260px;
  }

  .centered{
    font-size: 30px;
    line-height: 35px;
  }

  .svg-inline--fa {
    height: 0.5em;
    overflow: visible;
    vertical-align: -0.125em;
  }
  .goHome {
    position: fixed;
    display: flex;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgb(52, 52, 52);
    justify-content: center;
    align-items: center;
    padding: 6px;
    margin: 5px;
    z-index: 4;
    padding-top: 10px;
    bottom: 200px;
    right: 5px;
  }

  .goBack {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: rgb(52, 52, 52);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    margin: 5px;
    bottom: 115px;
    right: 5px;
  }

  .mybutton-QR {
    position: fixed;
    bottom: 30px;
    right: 5px;
    z-index: 10;
  }
  .screensaver-banner {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 10%;
    z-index: 5;
    bottom: 0;
    text-align: center;
    padding-top: 5%;
    font-size: 23px;
  }

}

.centered-media {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust based on your requirement */
  width: 100%;
}

.centered-media > div video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* This ensures the aspect ratio is maintained */
}

.error-button{
  margin-top: 20px;
  border-radius: 15px;
  width: 250px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

/*Mode portrait*/
@media (min-width:1080px) and (max-width: 1919px){
  .single-column {
    flex-direction: column;
  }


  .single-column-row {
    display: block !important;
    padding: 0 35px !important;
    margin: 0 !important;
    position: relative;
    object-fit: cover;
    object-position: center;
    top: 25px;
    width: 100%;
  }

  .single-column-row>* {
    width: 100%;
  }

  .single-column-row .image{
    height: 400px;
  }

  .myVideo {
    z-index: 5;
    position: fixed;
    object-fit: cover;
    object-position: center;
    background-color: black;
    display: none;
    width: 100%;
    height: 100%;
  }

  .centered{
    font-size: 55px;
    line-height: 50px;
  }

  .image{
    height: 300px;
  }

  .screensaver-container {
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center; /* Ajustement pour centrer horizontalement */
    align-items: center;
    width: 100%;
  }

  .ss_img1,
  .ss_img2 {
    height: auto;
  }

  .ss_img1 {
    width: 20%;
    margin-bottom: 35px;
    margin-left: 7px;
    animation: fadeInOut 3s infinite;
  }

  .ss_img2 {
    width: 85.5%;
    margin-bottom: 40px;
    margin-left: -40px;
    animation: fadeInOut 3s infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-container {
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    transition: background-color 5s ease-in-out; /* Transition over 5 seconds */
  }

  .fade-container.fade-in {
    background-color: rgba(0, 0, 0, 1); /* Fully opaque background */
  }

}

/*Mode site internet pour les blocs produits */
@media (max-width: 1079px) {
  .single-column {
    display: block;
  width: 100%;
  overflow: visible;
  }
  .date {
    display: block;
    line-height: normal;
    justify-content: end;
    align-items: center;
    height: 100%;
  }
  .center-crop {
    width: 94%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 1.25rem;
  }
}


@media only screen and (max-width: 768px) {
  /* Styles for large phones */
  .row {
    display: block !important;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    top: 8px;
    width: 100%;
  }
  .center-crop {
    width: 92%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    border-radius: 1.25rem;
  }
  .events {
    display: flex;
    width: 100%;
    height: 200px;
    text-align: center;
    margin-top: 2%;
    align-items: center;
    justify-content: center;
  }
  .fullDate{
    font-size: 18px;
    padding-top: 10px;
  }
  .center-weather {
    padding-left: 48px;
    font-size: 18px !important;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for small tablets */
  .fullDate{
    font-size: 20px;
    padding: 10px;
  }

  .center-weather {
    padding-left: 100px;
    font-size: 18px !important;
  }

}

@media only screen and (max-width: 600px) {
  /* Styles for small phones */
  .center-weather {
    padding-left: 100px;
    font-size: 18px !important;
  }
}


.single-column-row {
  display: block !important;
  padding: 0 35px !important;
  margin: 0 !important;
  position: relative;
  top: 25px;
  width: 100%;
}


.previousProductButton,
.nextProductButton {
  display: block !important;
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  z-index: 4;
  position: absolute;
  top: 50%; /* Adjust top position as needed */
  transform: translateY(-50%);
}

.previousProductButton {
  left: 10px; /* Adjust left position as needed */
}

.nextProductButton {
  right: 10px; /* Adjust right position as needed */
}


/* ========== ADAPTATION MOBILE & TABLETTE SUPPLÉMENTAIRE ========== */

/* Ajustement de l'écran de chargement */
.loading-screen {
  padding: 5%;
}

.loading-screen .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* Texte de chargement responsive */
.loading-screen p {
  font-size: clamp(1rem, 4vw, 2rem);
  text-align: center;
  margin-top: 3vh;
  padding: 0 10px;
  line-height: 1.4;
  word-break: break-word;
}

/* Pour petits écrans (mobiles) */
@media only screen and (max-width: 480px) {
  .events {
    display: flex;
    flex-direction: column; /* en colonne pour petits écrans */
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 1%;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .container-image {
    padding: 2px;
    margin-bottom: 5px;
    font-size: 0.9rem;
  }

  .loading-screen p {
    font-size: 1.2rem;
    margin-top: 4vh;
  }

  .feedback {
    width: 70px;
    height: 70px;
    font-size: 10px;
  }

  .goHome {
    height: 50px;
    width: 50px;
    bottom: 3%;
    right: 0%;
  }

  .goBack {
    height: 50px;
    width: 50px;
    bottom: 10%;
    right: 0%;
  }

  .image1,
  .image2 {
    height: 100px !important;
  }

  .image {
    border-radius: 1.5rem;
    height: 100px !important;
  }

  .center-crop {
    height: 200px;
  }

  .centered {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .video-iframe{
    height: 200px;
  }
}

/* Pour tablettes */
@media only screen and (min-width: 481px) and (max-width: 1024px) {
  .video-iframe{
    height: 150px;
  }
  .events {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 1%;
    align-items: center;
    justify-content: center;
    padding: 0;
    gap: 10px;
  }

  .container-image {
    padding: 0;
    margin-bottom: 8px;
    font-size: 1rem;
  }

  .loading-screen p {
    font-size: 1.5rem;
  }

  .feedback {
    width: 75px;
    height: 75px;
    font-size: 11px;
  }

  .goHome {
    height: 50px;
    width: 50px;
    bottom: 3%;
    right: 0%;
  }

  .goBack {
    height: 50px;
    width: 50px;
    bottom: 17%;
    right: 0%;
  }

  .image1,
  .image2 {
    height: 100px !important;
  }

  .image {
    border-radius: 1.5rem;
    height: 100px !important;
  }

  .center-crop {
    height: 200px;
  }

  .centered {
    font-size: 28px !important;
    line-height: 32px !important;
  }

  .row {
    display: block !important;
    padding: 0 10px !important;
    margin: 0 !important;
    position: relative;
    top: 8px;
    width: 100%;
  }

}
